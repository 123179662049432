import React, { useCallback, useMemo } from 'react';
import {
  AudioIcon,
  AutoRotateIcon,
  ContactFormIcon,
  // ExpandIcon,
  // FloorMapIcon,
  MapLineIcon,
  // FullScreenIcon,
  // HelpIcon,
  MapIcon,
  // ShareIcon,
} from 'components/icons';
import configs from 'configs';
// import { isMobile } from 'react-device-detect';

// import NotePlate from './NotePlate';

import './SchoolLayoutIcons.scss';
// import {
//   AquaAutoRotateIcon,
//   AquaFullscreenIcon,
//   AquaMapfloorplanIconV1,
//   PrimeMapFloorPlanIcon,
//   ShareIcon,
// } from 'components/icons/AquaIcons';
// import AquaExtraIcons from './AquaExtraIcons';
// import { isMobile } from 'react-device-detect';
import HoveringPlate from 'components/HoveringPlate';
import { HomeIcon } from 'components/icons/HomeIcon';

export default function PrimeLayoutIcons(props) {
  const {
    currentPano,
    color,
    // toggleHelpButton,
    featuredMedia,
    isShowAudioIcon,
    // canFullscreen,
    // menuPosition,
    // handleFullScreen,
    isShowMapFloorPlan,
    setIsActiveMap,
    isActiveMap,
    mapShowed,
    // setOpenDropDown,
    // openDropDown,
    // handleSelectMap,
    scenes,
    // floorMaps,
    switchMode,
    handleUpdateRoute,
    onSelect,
    isPrimeStyle,
    isShowOnMap,
    shouldShowMapIcon,
    isAutoPlay,
    // fullScreen,
    setIsOpenMapfloorplanModal,
  } = props;

  // const handleGoHome = () => {
  //   window.location.href = 'https://khaihoanprime.vn/';
  // };

  const isIndoorMap = useMemo(() => {
    return !isNaN(currentPano?.id);
  }, [currentPano]);

  // const isMobile = useMemo(() => window.innerWidth <= 767, []);

  const getViewPointTitle = (title) => {
    if (isNaN(title)) {
      return title;
    }
    return '';
  };

  const handleMapClick = useCallback((event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = (event.clientX - rect.left) / rect.width;
    const y = (event.clientY - rect.top) / rect.height;

    console.log(`Clicked position: (${x.toFixed(4)}, ${y.toFixed(4)})`);
  }, []);

  return (
    <div
      className={`icons_right ${isPrimeStyle ? 'icons_right-container' : ''}`}
    >
      {isShowMapFloorPlan && (
        <>
          {/* <HoveringPlate
            textHover={'Mặt bằng tổng thể'}
            isPrimeStyle={isPrimeStyle}
          > */}
          <div
            className={`icon position-relative ${
              isPrimeStyle ? 'icon-primeStyle' : ''
            } 
              `}
            onClick={() => {
              setIsActiveMap(!isActiveMap);
              setIsOpenMapfloorplanModal && setIsOpenMapfloorplanModal();
            }}
          >
            {/* <MapFloorPlanIcon active={isActiveMap} /> */}
            <MapLineIcon active={isActiveMap} activeColor={color} />
          </div>
          {/* </HoveringPlate> */}

          <div
            className={`mapfloorplan_container ${
              isPrimeStyle ? 'mapfloorplan_container-primeStyle' : ''
            } ${isActiveMap ? 'open-aacorp' : ''} ${
              isIndoorMap ? 'indoor-map' : ''
            }`}
          >
            {mapShowed && (
              <>
                <div className="map_image" onClick={handleMapClick}>
                  <div
                    className="map_close_btn"
                    onClick={() => setIsActiveMap(false)}
                  >
                    <i className="icon-close"></i>
                  </div>
                  <img src={mapShowed.image.url} alt={mapShowed.image.name} />
                  {mapShowed.objects.map((viewpoint) => {
                    const currentPoint = viewpoint.id === currentPano._id;
                    const [left, top] = viewpoint.coordinates;
                    const leftInPercent = left * 100;
                    const topInPercent = top * 100;
                    const currentScene =
                      scenes.find((s) => s._id === viewpoint.id) || {};

                    return (
                      <div
                        key={viewpoint.id}
                        className={`viewpoint-container  ${
                          currentPoint
                            ? 'current-viewpoint aquaAnimation primeColor'
                            : `viewpoint ${
                                isPrimeStyle ? 'aquaViewPoint primeColor' : ''
                              }`
                        } `}
                        style={{
                          left: `${leftInPercent}%`,
                          top: `${topInPercent}%`,
                        }}
                        onClick={() => {
                          if (
                            currentScene.id.indexOf('tong-the-toan-canh-02') !==
                            -1
                          ) {
                            onSelect(
                              currentScene.groupId,
                              currentScene.id,
                              true,
                              true
                            );
                          } else {
                            onSelect(currentScene.groupId, currentScene.id);
                          }
                        }}
                      >
                        <HoveringPlate
                          textHover={getViewPointTitle(currentScene.title)}
                        >
                          <div className="hover-point"></div>
                        </HoveringPlate>
                      </div>
                    );
                  })}
                </div>

                {/* {isPrimeStyle && (
                  <div
                    className="mapfloorplan_close"
                    onClick={() => setIsActiveMap(true)}
                  >
                    <button className="mapfloorplan_close-btn">&times;</button>
                  </div>
                )} */}
              </>
            )}
          </div>
        </>
      )}

      {/* {isPrimeStyle && <AquaExtraIcons />} */}

      {shouldShowMapIcon && isPrimeStyle && (
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          style={{
            display: isShowOnMap ? 'flex' : 'none',
          }}
          onClick={() => handleUpdateRoute(configs.mapRoute)}
        >
          <MapIcon />
        </div>
      )}

      {isShowAudioIcon && (
        <AudioIcon
          activeColor={color}
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
        />
      )}

      {
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          onClick={() => switchMode()}
        >
          <AutoRotateIcon shouldRotate={isAutoPlay} activeColor={color} />
        </div>
      }

      {/* {canFullscreen() && menuPosition !== 'bottom' && (
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          onClick={handleFullScreen}
        >
          <FullScreenIcon fullScreen={fullScreen} activeColor={color} />
        </div>
      )} */}

      <div
        className={`icon ${isPrimeStyle ? 'icon-primeStyle home' : ''}`}
        onClick={() => onSelect('welcome', 'tong-the-toan-canh-01')}
      >
        <HomeIcon />
      </div>

      {/* 
      <HoveringPlate textHover={'Hướng dẫn'}>
        <div
          className={`icon ${isPrimeStyle ? 'icon-aquaStyle' : ''}`}
          onClick={toggleHelpButton}
        >
          <HelpIcon />
        </div>
      </HoveringPlate> */}

      {featuredMedia.map && (
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          onClick={() => handleUpdateRoute(featuredMedia.map.id)}
        >
          <MapLineIcon active={isActiveMap} activeColor={color} />
        </div>
      )}

      {featuredMedia.contact && (
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
        >
          <ContactFormIcon />
        </div>
      )}

      {/* <HoveringPlate textHover={'Chia sẻ'}>
        <div
          className={`icon ${isPrimeStyle ? 'icon-aquaStyle' : ''}`}
          onClick={handleShare}
        >
          <ShareIcon />
        </div>
      </HoveringPlate> */}

      {/* <div
        className={`icon ${isPrimeStyle ? 'icon-aquaStyle' : ''}`}
        onClick={() => {
          toggleRotate();
        }}
      >
        <AutoRotateIcon shouldRotate={isAutoPlay} activeColor={color} />
      </div> */}
    </div>
  );
}
