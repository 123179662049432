import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAutoRotate } from 'store/actions';

function findObjectIndex(arr, item) {
  if (!item) return 1;
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].id === item.id) {
      return i; // Trả về vị trí nếu tìm thấy
    }
  }
  return -1; // Trả về -1 nếu không tìm thấy
}

function moveTime(tocDo, banKinh) {
  // Tính chu vi của đường tròn
  var chuVi = 2 * Math.PI * banKinh;

  // Tính thời gian di chuyển
  var thoiGian = chuVi / tocDo;

  return thoiGian;
}

const HandleAutoRotate = ({
  shouldRotate,
  scenes,
  currentPano,
  onChangeScene,
}) => {
  const autoPlay = useSelector((state) => state.autoPlayMode);
  const autoRotateSpeed = useSelector((state) => state.tour.autoRotateSpeed);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAutoRotate(autoPlay));
  }, [autoPlay, dispatch]);

  const isRotate = useMemo(() => {
    if (!shouldRotate || !autoPlay) {
      return false;
    } else return true;
  }, [autoPlay, shouldRotate]);

  const [currentIndex, setCurrentIndex] = useState(
    scenes.length !== 0 ? findObjectIndex(scenes, currentPano) : 0
  );
  const currentScene = useMemo(
    () => scenes[currentIndex],
    [currentIndex, scenes]
  );

  useEffect(() => {
    if (scenes.length === 0) return;
    setCurrentIndex(findObjectIndex(scenes, currentPano));
  }, [currentPano, scenes]);

  useEffect(() => {
    if (scenes.length === 0) return;
    const fetchData = () => {
      if (isRotate) {
        // Kiểm tra xem currentIndex đã đạt đến cuối mảng chưa
        if (currentIndex === scenes.length - 1) {
          setCurrentIndex(0); // Reset lại currentIndex về 0 để lặp lại từ đầu
        } else {
          setCurrentIndex(currentIndex + 1); // Chuyển sang đối tượng tiếp theo
        }
        onChangeScene(currentScene.groupId, currentScene.id);
      }
    };

    const timeoutId = setTimeout(
      fetchData,
      moveTime(autoRotateSpeed, 5) * 1000
    );

    return () => {
      clearTimeout(timeoutId); // Xóa timeout khi component unmount
    };
    // eslint-disable-next-line
  }, [
    autoRotateSpeed,
    currentIndex,
    currentScene,
    autoPlay,
    isRotate,
    scenes,
    onChangeScene,
  ]);

  return <></>;
};

export default HandleAutoRotate;
